import React from 'react';
import { IconType } from '~/utils';
import { BulletIcon } from '@applyboard/ui-icons';
import { Flex, Heading } from '@applyboard/crystal-ui';
import { ExpandableCard } from '~/components/uie-fix/ExpandableCard';
import { useApplicationCard, CardSection, useAppProcessing } from '../../context';

type CardProps = {
  id: CardSection;
  icon: IconType;
  title: string;
  children: React.ReactNode;
};

export function Card(props: CardProps) {
  const { application } = useAppProcessing();
  const { isExpanded, toggle } = useApplicationCard(props.id);
  const isChanged = application.meta.changeSet?.includes(props.id);

  return (
    <ExpandableCard
      id={props.id}
      icon={props.icon}
      title={
        <Flex align="center" gap={2}>
          <Heading level={3}>{props.title}</Heading>
          {isChanged && <BulletIcon intent="warning" variant="fill" />}
        </Flex>
      }
      isExpanded={isExpanded}
      onIsExpandedChange={(expanded) => toggle(expanded)}
    >
      {props.children}
    </ExpandableCard>
  );
}
